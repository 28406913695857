import {
  IFeatureTicker,
  IFeatureTickerFields,
  IFinancialProofBar,
  IFinancialProofBarFields,
  ISocialProofBar,
  ISocialProofBarFields
} from '@/types/contentful';
import React from 'react';
import FeatureTicker from '../FeatureTicker';
import { SocialProofBar } from '../SocialProofBar';
import { FinancialProofBar } from '../FinancialProofBar';

type IAdditionalContent =
  | ISocialProofBar
  | IFeatureTicker
  | IFinancialProofBar
  | undefined;

interface ContentComponentProps {
  additionalContent: IAdditionalContent | null;
}

const AdditionalContent: React.FC<ContentComponentProps> = ({
  additionalContent
}) => {
  if (!additionalContent) return null;

  const { sys, fields } = additionalContent;

  switch (sys?.contentType?.sys?.id) {
    case 'socialProofBar':
      return <SocialProofBar {...(fields as ISocialProofBarFields)} />;
    case 'featureTicker':
      return <FeatureTicker {...(fields as IFeatureTickerFields)} />;
    case 'financialProofBar':
      return <FinancialProofBar {...(fields as IFinancialProofBarFields)} />;
    default:
      return null; // Or some fallback UI
  }
};

export default AdditionalContent;
