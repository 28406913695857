import React from 'react';
import { IFinancialProofBarFields } from '../../../types/contentful';
import { getImageDataWithHttps } from '~/utils/contentful';
import { Asset } from 'contentful';
import Image from 'next/image';

interface Props extends IFinancialProofBarFields {
  includeFullWidth?: boolean;
}

export function FinancialProofBar({
  headline1,
  headline2,
  headline3,
  headline4,
  image1,
  image2,
  image3,
  image4
}: Props) {
  const headlines = [headline1, headline2, headline3, headline4];
  const images = [image1, image2, image3, image4];

  return (
    <section
      className="flex w-full flex-wrap items-center bg-white pb-7 pt-6 tiny:z-[1] tiny:mx-auto tiny:mb-10 tiny:mt-[-70px] tiny:h-[32%] tiny:w-[90%] tiny:max-w-[500px] tiny:justify-center tiny:rounded-3xl tiny:px-0 tiny:text-center mobile:h-[27%] min-[450px]:w-[90%] min-[450px]:px-10 min-[502px]:px-16 tablet:ml-[68px] tablet:mt-0 tablet:h-full tablet:w-[92%] tablet:max-w-[900px] tablet:justify-center tablet:gap-0 tablet:rounded-none tablet:px-0 min-[820px]:ml-12 min-[820px]:w-full min-[1025px]:ml-10 desktop:mb-28 desktop:ml-4 desktop:mt-0 desktop:w-full desktop:max-w-[1440px]"
      data-testid="financial-proof-bar"
    >
      {headlines.map(
        (headline, index) =>
          headline && (
            <div
              key={headline}
              className="mb-3 flex tiny:w-full tiny:items-center tablet:mr-1 tablet:h-32 tablet:max-w-[170px] tablet:flex-col tablet:items-start min-[950px]:mr-5 min-[950px]:max-w-[200px] desktop:max-w-[200px]"
              data-testid="financial-proof-bar-item"
            >
              {images[index] && (
                <div
                  className="relative ml-5 mr-3 h-7 w-7 tablet:mb-2 tablet:ml-0"
                  data-testid="financial-proof-bar-item-subheadline"
                >
                  <Image
                    {...getImageDataWithHttps(images[index] as Asset)}
                    width={30}
                    height={30}
                  />
                </div>
              )}
              <div
                className="text-left tiny:text-sm tablet:text-base"
                data-testid="financial-proof-bar-item-headline"
              >
                {headline}
              </div>
            </div>
          )
      )}
    </section>
  );
}
