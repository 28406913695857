import { IFeatureTickerFields } from '@/types/contentful';
import Image from 'next/legacy/image';
import React from 'react';
import Marquee from 'react-fast-marquee';
import { ColorTokenProp } from 'src/theme/tokens/color';
import { getImageDataWithHttps } from 'src/utils/contentful';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import RichText from '../RichText';

const FeatureTicker: React.FC<
  IFeatureTickerFields & Partial<Parameters<typeof Marquee>[0]>
> = ({
  backgroundColor = 'white',
  featureList = [],
  tickerSpeed = 4,
  textColor = 'black',
  className
}) => {
  const isMobile = !useMediaQuery('desktop');

  return (
    <Marquee
      pauseOnHover={isMobile ? false : true}
      pauseOnClick={isMobile ? true : false}
      speed={tickerSpeed}
      gradient={false}
      autoFill={true}
      data-testid="feature-ticker"
      style={{ backgroundColor }}
      className={`${className ?? ''}  h-[75px] bg-black`}
    >
      {featureList.map(({ fields: { title, asset, content } }, index) => (
        <div
          key={JSON.stringify(title) + index}
          // @TODO: Should look into being able to pass styling options into RichText component.
          className="mx-[5px] inline-flex h-full gap-[10px] align-middle [&>p]:flex [&>p]:items-center"
          data-testid="feature-ticker-feature"
        >
          <div className="relative h-[29px] w-[29px]">
            <Image
              {...getImageDataWithHttps(asset)}
              layout="fill"
              width={undefined}
              height={undefined}
            />
          </div>
          <RichText colour={textColor as ColorTokenProp} data={content} />
        </div>
      ))}
    </Marquee>
  );
};

export default FeatureTicker;
