import styled from '../../theme/styled-with-theme';
import { pxToRem } from '../../styles/utils';
import { mediaQueries } from '../../styles/media-queries';

import { ColorToken } from '../../theme/tokens/color';

import { Box } from '../Box';
import { TextWithOptions } from '../Text/TextWithOptions';
import { BlockContainer, Column } from '../Layout';
import { GlImage } from '../Image';

import {
  ParagraphWithLineBreaks as RichTextParagraph,
  InlineAnchor as RichInlineAnchor,
  handleColorType
} from '../RichText/styles';

export const MobileVersion = styled('div')`
  display: block;

  ${mediaQueries.tablet} {
    display: none;
  }
`;

export const DesktopBlockContainer = styled(BlockContainer)`
  display: none;

  ${mediaQueries.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      width: 100%;
    }

    [data-testid='social-proof-bar'] {
      width: 100%;
      max-width: initial;
      margin-top: ${pxToRem(24)};
    }
  }
`;

export const RelativeBox = styled(Box)`
  position: relative;
`;

export const CTAText = styled(TextWithOptions)`
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.spacing.s};
`;

export const MobileSubheadingText = styled(TextWithOptions)<{
  mobileSubheadingSize?: string;
}>`
  font-size: ${({ mobileSubheadingSize }) => `${mobileSubheadingSize}rem`};
  margin: 0 auto;
  padding-bottom: ${({ theme }) => theme.spacing.m};
`;

export const MobileImage = styled(GlImage)`
  ${mediaQueries.tablet} {
    display: none;
  }
`;

export const DesktopImage = styled(GlImage)<{ visibility: string }>`
  height: 100%;
  max-width: ${pxToRem(630)};
  max-height: ${pxToRem(630)};
  visibility: ${({ visibility }) => visibility};
`;

export const SubheadText = styled(TextWithOptions)<{
  subheadingSize?: string;
}>`
  font-size: ${({ subheadingSize }) => `${subheadingSize}rem`};
  line-height: 1.125;
`;

export const InlineAnchor = styled(RichInlineAnchor, {
  shouldForwardProp: (prop) => prop !== 'hoverColor'
})<{
  hoverColor?: ColorToken;
}>`
  ${({ theme, hoverColor = 'lightButton' }) => `
    &:hover {
      color: ${handleColorType(theme, hoverColor)};
    }

    ${mediaQueries.desktop} {
      &:hover {
        color:
          ${handleColorType(theme, hoverColor)};
      }
    }
  `}
`;

export const PrivacyPolicyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const DesktopPolicyWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const CaptionWithLineBreaks = styled(RichTextParagraph)`
  max-width: ${pxToRem(272)};
  color: #757575;

  ${mediaQueries.tablet} {
    text-align: center;
  }

  ${mediaQueries.desktop} {
    min-width: 0;
    max-width: ${pxToRem(496)};
  }
`;

export const SpacerColumn = styled(Column)`
  ${mediaQueries.tablet} {
    display: none;
  }

  ${mediaQueries.desktop} {
    display: flex;
  }
`;

export const AdditionalContentWrapper = styled('div')`
  margin-top: ${pxToRem(20)};
  display: flex;
  white-space: pre;
  font-size: ${pxToRem(14)};
  max-width: 21rem;
  align-items: flex-start;
`;
export const StyledIcon = styled('img')`
  margin-right: ${pxToRem(6)};
  margin-top: ${pxToRem(-1)};

  ${mediaQueries.tablet} {
    margin-top: ${pxToRem(1)};
  }
`;
