import React from 'react';
import Image from 'next/legacy/image';
import { Block, BLOCKS, INLINES, Inline } from '@contentful/rich-text-types';
import { RenderNode } from '@contentful/rich-text-react-renderer';

import { IFullwidthHero20Fields } from '../../../types/contentful';

import cx from 'classnames';
import { Text } from '../Text';
import RichText from '../RichText';
import { EmbeddedInlineEntryNode } from '../RichText/Nodes';

import { InlineAnchor } from '../FullwidthHero/styles';

import shield from '@/images/shield.svg';
import { ColorTokenProp } from 'src/theme/tokens/color';

type PrivacyPolicyDisclaimerFields = Pick<
  IFullwidthHero20Fields,
  'privacyPolicyDisclaimer' | 'displayPrivacyPolicyIcon'
>;

interface PrivacyPolicyDisclaimerProps extends PrivacyPolicyDisclaimerFields {
  noMargin?: boolean;
  color?: ColorTokenProp | string;
  centerAlignTablet?: boolean;
}

export default function PrivacyPolicyDisclaimer({
  privacyPolicyDisclaimer,
  displayPrivacyPolicyIcon,
  noMargin = false,
  color = 'inputLabelText',
  centerAlignTablet = false
}: PrivacyPolicyDisclaimerProps) {
  const RICH_TEXT_OVERRIDE: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
      return (
        <Text
          className={cx(
            'm-auto w-full whitespace-pre-wrap break-words text-left tablet:m-0 tablet:w-3/4 desktop:w-10/12 [&:not(:last-child)]:mb-4',
            {
              'tablet:text-left': !centerAlignTablet,
              'tiny:text-center desktop:text-left': centerAlignTablet
            }
          )}
          as="p"
          fontSize={{
            0: '2xs',
            tablet: 's'
          }}
          fontWeight="400"
          colour={color}
        >
          {children}
        </Text>
      );
    },
    [BLOCKS.HEADING_6]: (_node: Block | Inline, children) => (
      <Text
        as="p"
        fontSize={{
          0: 'h6_0',
          tablet: 'h6_tablet'
        }}
        fontWeight="500"
        marginBottom="m"
        textAlign={{
          0: displayPrivacyPolicyIcon !== false ? 'left' : 'center',
          tablet: 'left'
        }}
      >
        {children}
      </Text>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <InlineAnchor
        href={node.data.uri}
        colour={color}
        hoverColor="inlineAnchor"
      >
        {children}
      </InlineAnchor>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => (
      <EmbeddedInlineEntryNode node={node} colour={color} fontWeight="p">
        {children}
      </EmbeddedInlineEntryNode>
    )
  };

  return (
    <div className="column m-auto w-full tablet:m-0">
      <div
        className={cx('flex flex-row items-center justify-between', {
          'm-0': noMargin,
          'm-auto tablet:m-0': !noMargin
        })}
      >
        {/* If displayPrivacyPolicyIcon isn't set then we want to default to displaying the icon */}
        {displayPrivacyPolicyIcon !== false && <Image src={shield} alt="" />}
        <div className="flex flex-col">
          <RichText
            data={privacyPolicyDisclaimer}
            overrideOptions={RICH_TEXT_OVERRIDE}
          />
        </div>
      </div>
    </div>
  );
}
