import React from 'react';
import { ISocialProofBarFields } from '../../../types/contentful';

export function SocialProofBar({
  backgroundColor = '#DBF6F1',
  textColor,
  headline1,
  headline2,
  headline3,
  headline4,
  subheadline1,
  subheadline2,
  subheadline3,
  subheadline4,
  useNewUi
}: ISocialProofBarFields) {
  const headlineClassNames = 'text-2xl font-semibold leading-none';
  const headlines = [headline1, headline2, headline3, headline4];
  const subheadlines = [subheadline1, subheadline2, subheadline3, subheadline4];

  return (
    <section
      className={
        useNewUi
          ? 'w-full overflow-x-scroll tablet:overflow-auto'
          : 'flex flex-wrap items-start justify-center bg-[#DBF6F1] py-9 tablet:gap-7 desktop:gap-10'
      }
      style={{ backgroundColor }}
      data-testid="social-proof-bar"
    >
      {useNewUi && (
        <div
          className="mx-auto inline-flex h-33 w-full min-w-[660px] items-center justify-center gap-6 px-5 py-6 tablet:px-10"
          data-testid="social-proof-bar-inner"
        >
          {headlines.map(
            (headline, index) =>
              headline && (
                <div
                  key={headline}
                  className="inline-flex max-w-36 shrink-0 grow basis-0 flex-col items-start justify-start gap-1 tablet:max-w-44"
                  data-testid="social-proof-bar-item"
                >
                  <div
                    className={headlineClassNames}
                    data-testid="social-proof-bar-item-headline"
                    style={{ color: textColor }}
                  >
                    {headline}
                  </div>
                  {subheadlines[index] && (
                    <div
                      className="text-sm font-normal leading-[18px]"
                      data-testid="social-proof-bar-item-subheadline"
                      style={{ color: textColor }}
                    >
                      {subheadlines[index]}
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      )}
      {!useNewUi && (
        <>
          {headlines.map(
            (headline, index) =>
              headline && (
                <div
                  key={headline}
                  className="w-1/2 shrink-0 p-4 tablet:w-auto tablet:max-w-[160px] tablet:shrink tablet:p-0"
                  data-testid="social-proof-bar-item"
                >
                  <div
                    className={headlineClassNames}
                    data-testid="social-proof-bar-item-headline"
                    style={{ color: textColor }}
                  >
                    {headline}
                  </div>
                  {subheadlines[index] && (
                    <div
                      className="pt-3 text-sm font-light"
                      data-testid="social-proof-bar-item-subheadline"
                      style={{ color: textColor }}
                    >
                      {subheadlines[index]}
                    </div>
                  )}
                </div>
              )
          )}
        </>
      )}
    </section>
  );
}
